import React from 'react';

function Dashboard() {
  return (
    <div>
      <h2>Dashboard</h2>
      <p>Dashboard functionality placeholder.</p>
    </div>
  );
}

export default Dashboard;
